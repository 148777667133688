import { useFetcher, useLocation, useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";

import { ButtonOld } from "~/components/ButtonOld";
import { Image } from "~/components/Image";
import { Link } from "~/components/Link";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { Modal } from "~/components/Modal";
import { NumberSelector } from "~/components/NumberSelector";
import { Stock } from "~/components/Stock";
import { CartAddIcon, CheckIcon, MailIcon, StarIcon, UserIcon } from "~/components/svg/icons";
import { Switch } from "~/components/Switch";
import { useComparator } from "~/hooks/use-comparator";
import { useFavorites } from "~/hooks/use-favorites";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { getCharacteristicValue } from "~/services/characteristics";
import {
  getProductBarredPrice,
  getProductFinalPrice,
  getProductTags,
  getProductWcPrice,
  isProductPriceVisible,
  isProductQuantityDecimal,
} from "~/services/product";
import { cn } from "~/utils/classnames";
import { formatCurrency } from "~/utils/formatters";

import "./ProductCard.css";
import type { ProductCardProps } from "./ProductCard.types";

/**
 * Composant carte d'un produit
 */
export const ProductCard = ({
  product,
  activeContactId,
  favorites,
  comparator,
  contact,
  className,
  ...props
}: ProductCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isReassortModalOpen, setIsReassortModalOpen] = useState(false);
  const [isReassortModalAlreadyOpened, setIsReassortModalAlreadyOpened] = useState(false);

  const isUserConnected = !!activeContactId;
  const mainImage = product.images.sort((a, b) => a.position - b.position)[0] || null;
  const subheadingThumbnail = getCharacteristicValue(product, "sous-titre-vignette");
  const tags = getProductTags(product);

  const cartFetcher = useFetcher<any>();
  const [_isCartFetcherLoading, isCartFetcherDone] = useManagedFetcher({
    fetcher: cartFetcher,
    toastSuccessMessage: "Produit ajouté au panier",
  });

  const { handleFavoritesChange, isInFavorites } = useFavorites({
    contactId: activeContactId || null,
    favorites: favorites || [],
  });

  const {
    handleComparatorProductChange,
    isInComparator,
    isComparatorFetcherLoading,
    isComparatorFetcherFailed,
  } = useComparator({ comparator });

  useEffect(() => {
    if (
      (product.stocks[0]?.etatStock === 3 || product.stocks[0]?.etatStock === 4) &&
      isCartFetcherDone &&
      !isReassortModalAlreadyOpened
    ) {
      setIsReassortModalOpen(true);
      setIsReassortModalAlreadyOpened(true);
    }
  }, [isCartFetcherDone, product, isReassortModalAlreadyOpened]);

  return (
    <div className={cn("ProductCard", className)} {...props}>
      {isReassortModalOpen ? (
        <Modal
          title="Produit ajouté au panier"
          description={
            product.stocks[0]?.etatStock === 3
              ? "Ce produit est en cours de réapprovisionnement, le prix est donc susceptible d'évoluer"
              : product.stocks[0]?.etatStock === 4
                ? "Ce produit est sur commande, le prix est donc susceptible d'évoluer"
                : null
          }
          size="sm"
          isOpen={isReassortModalOpen}
          onClose={() => setIsReassortModalOpen(false)}
          className="ProductCard-modal"
        >
          <ButtonOld
            label="OK"
            color="black"
            onClick={() => {
              setIsReassortModalOpen(false);
            }}
            className="mx-auto"
          />
        </Modal>
      ) : null}

      {tags && tags.length > 0 ? (
        <div className="absolute right-0 top-16 flex flex-col items-end gap-1.5">
          {tags.map((tag) => (
            <div
              key={tag?.id}
              className={cn(
                `bg-${tag?.color}-200 text-${tag?.color}-700`,
                "rounded-bl-[8px] rounded-tl-[8px] px-2.5 py-1.5 text-xs font-medium"
              )}
            >
              {tag?.label}
            </div>
          ))}
        </div>
      ) : null}

      <div className="ProductCard-brandBadge">
        {mainImage ? (
          <Image
            src={product.brand?.images[0]?.url}
            alt={product.brand?.title}
            className="ProductCard-brandBadge-image"
            fallbackElement={<span className="text-grey-900">{product.brand?.title}</span>}
          />
        ) : (
          <p className="text-grey-900">{product.brand?.title}</p>
        )}
      </div>

      {isUserConnected ? (
        <label className="ProductCard-addFavoritesBadge" aria-label="Ajouter aux favoris">
          <StarIcon className="ProductCard-addFavoritesBadge-icon svgIcon" />
          <input
            type="checkbox"
            className="ProductCard-addFavoritesBadge-checkbox"
            defaultChecked={isInFavorites(product.id)}
            onChange={(e) =>
              handleFavoritesChange({ productId: product.id, checked: e.target.checked })
            }
          />
        </label>
      ) : null}

      <Link to={`/${product.categories[0]?.slug}/${product.slug}`} className="ProductCard-link">
        <Image src={mainImage?.url} alt={product.title} className="ProductCard-image" />
        <h2 className="ProductCard-title">{product.title}</h2>
        <p className="ProductCard-description">{subheadingThumbnail}</p>
        <p className="ProductCard-reference">Réf. POwR : {product.reference}</p>
        {isUserConnected ? (
          isProductPriceVisible({ product, contact }) && product.price ? (
            <>
              <div className="mb-2 flex flex-wrap items-end gap-4">
                {getProductBarredPrice(product) ? (
                  <span className="relative text-grey-700">
                    {formatCurrency(getProductBarredPrice(product))}{" "}
                    <div className="absolute -left-[5%] top-1/2 h-[1px] w-[110%] -rotate-6 bg-grey-700" />
                  </span>
                ) : null}
                <span className="ProductCard-price">
                  {formatCurrency(getProductFinalPrice(product))}{" "}
                  <span className="ProductCard-price-taxeInfo">
                    HT{product.categories[0]?.slug.startsWith("module") ? " / Module" : ""}
                  </span>
                </span>
              </div>
              {getProductWcPrice(product) ? (
                <p className="ProductCard-wcPrice">
                  Soit {formatCurrency(getProductWcPrice(product), 3)}{" "}
                  <span className="ProductCard-wcPrice-taxeInfo">HT / Wc</span>
                </p>
              ) : null}
            </>
          ) : (
            <p>
              <span className="mb-2 text-sm font-semibold text-grey-600">Prix sur demande</span>
            </p>
          )
        ) : null}
      </Link>

      {isUserConnected ? (
        <div className="ProductCard-stock">
          <Stock
            stock={product.stocks[0]}
            productId={product.id}
            productCategory={product.categories[0]?.slug || null}
          />
        </div>
      ) : null}

      {isUserConnected ? (
        <div className="ProductCard-comparator">
          <Switch
            key={isComparatorFetcherFailed?.toString()}
            disabled={!!isComparatorFetcherLoading}
            label="Comparer ce produit"
            className="ProductCard-comparator-switch"
            onChange={(e) => {
              handleComparatorProductChange({
                id: product.id,
                categorySlug: product.categories[0]?.slug || "",
                checked: e.target.checked,
              });
            }}
            defaultChecked={isInComparator(product.id) || false}
          />
        </div>
      ) : null}
      {isUserConnected ? (
        isProductPriceVisible({ product, contact }) ? (
          <cartFetcher.Form
            className="ProductCard-buttonsContainer"
            method="post"
            action="/cart/actions?/addItem"
          >
            <input type="hidden" name="productId" value={product.id} />
            <NumberSelector
              id={`${product.slug}-quantity`}
              name="quantity"
              defaultValue={1}
              step={isProductQuantityDecimal(product) ? "any" : 1}
              min={1}
              max={9999}
            />
            <ButtonOld
              type="submit"
              label="Ajouter au panier"
              IconComponent={
                cartFetcher.state === "idle" && cartFetcher.data ? (
                  <CheckIcon />
                ) : cartFetcher.state === "submitting" || cartFetcher.state === "loading" ? (
                  <LoadingSpinner />
                ) : (
                  <CartAddIcon />
                )
              }
              className="!px-4 !py-4"
            />
          </cartFetcher.Form>
        ) : (
          <ButtonOld
            as="a"
            href={`/nous-contacter?subject=estimate&product-slug=${product.slug}`}
            label="Nous consulter"
            IconComponent={<MailIcon />}
            className="!px-4 !py-4"
          />
        )
      ) : (
        <ButtonOld
          onClick={() => navigate(`/connexion?redirect=${location.pathname}`)}
          label="Se connecter"
          color="black"
          IconComponent={<UserIcon />}
        />
      )}
    </div>
  );
};
